import { saveAs } from 'file-saver';
import { gql, useClient } from 'urql';
import { useQueryParams } from 'use-query-params';

import { TransactionSearchOrder } from '@/globalTypes';
import { mapVariables } from '@/utils';
import {
  PlayerGameRoundsReport,
  PlayerGameRoundsReportVariables,
} from './__generated__/PlayerGameRoundsReport';
import { queryParams } from './queryParams';

const PLAYER_GAME_ROUNDS_REPORT_QUERY = gql`
  query PlayerGameRoundsReport(
    $id: String
    $rawId: String
    $playerId: ID
    $brand: BrandEnum
    $gameRoundId: String
    $minAmount: PositiveBigDecimal
    $maxAmount: PositiveBigDecimal
    $from: OffsetDateTime
    $to: OffsetDateTime
    $currency: String
    $paymentStatus: [PaymentStatus!]
    $paymentMethod: String
    $adjustmentType: AdjustmentType
    $adjustmentCategory: AdjustmentCategory
    $orderBy: TransactionSearchOrder
    $desc: Boolean
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    viewer {
      id
      exportedTransactionsV2(
        id: $id
        rawId: $rawId
        playerId: $playerId
        brand: $brand
        gameRoundId: $gameRoundId
        minAmount: $minAmount
        maxAmount: $maxAmount
        from: $from
        to: $to
        currency: $currency
        paymentStatus: $paymentStatus
        transactionTypes: [CasinoBet]
        paymentMethod: $paymentMethod
        adjustmentType: $adjustmentType
        adjustmentCategory: $adjustmentCategory
        orderBy: $orderBy
        desc: $desc
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        url
      }
    }
  }
`;

// The orders of game rounds query is not the same as for exportedTransactionsV2
const gameRoundOrderByToTransactionOrderBy = (orderBy?: string) => {
  if (!orderBy) {
    return undefined;
  }

  // get the matching value from transaction search order or go with undefined
  return Object.entries(TransactionSearchOrder).find(
    ([key]) => key === orderBy,
  )?.[1];
};

export default function usePlayerGameRoundsReport(playerId: string) {
  const [query] = useQueryParams(queryParams);
  const client = useClient();

  const generate = async () => {
    const variables: PlayerGameRoundsReportVariables = {
      ...mapVariables(query),
      orderBy: gameRoundOrderByToTransactionOrderBy(query.orderBy),
      playerId,
    };

    return client
      .query<PlayerGameRoundsReport, PlayerGameRoundsReportVariables>(
        PLAYER_GAME_ROUNDS_REPORT_QUERY,
        variables,
      )
      .toPromise()
      .then((res) => {
        const url = res.data?.viewer.exportedTransactionsV2.url;

        if (url) {
          saveAs(url, 'player-game-rounds-report.csv');
        }
      });
  };

  return {
    generate,
  };
}
